import { useQuery } from "@apollo/client";
import { navigate } from "gatsby";
import React from "react";
import { GET_PRODUCTS_QR_METAFIELD } from "../g/queries/get-product-info";

interface ILocation {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  key: string;
  origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  state: null;
}

const QRTemplate = ({ location }: { location: ILocation }) => {
  const code = location.pathname.split("/").pop();
  useQuery(GET_PRODUCTS_QR_METAFIELD, {
    variables: {
      namespace: "custom_fields",
      key: "cf_qr",
    },

    onCompleted: ({ products }) => {
      const product = products?.edges.filter(
        (p: ProductNode) => p.node.metafield?.value === code
      );
      const handle = product.pop();
      if (handle.node.handle) navigate(`/products/${handle.node.handle}`);
    },
  });
  return <></>;
};

export default QRTemplate;
