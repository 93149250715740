import { gql } from "@apollo/client";

export const GET_PRODUCT_INFO = gql`
  query productVariant($id: ID!) {
    node(id: $id) {
      id
    }
    products(first: 1) {
      edges {
        node {
          id
          title
          images(first: 1) {
            edges {
              node {
                originalSrc
              }
            }
          }
        }
      }
      sellingPlanGroups(first: 2) {
        edges {
          node {
            name
            sellingPlans(first: 2) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_VARIANT_ID = gql`
  query getProductByVariantId($input: ID!) {
    node(id: $input) {
      ... on ProductVariant {
        id
        product {
          id
          title
          images(first: 1) {
            edges {
              node {
                originalSrc
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS_QR_METAFIELD = gql`
  query getProductsMetafields($namespace: String!, $key: String!) {
    products(first: 5) {
      edges {
        node {
          handle
          id
          metafields(first: 3) {
            edges {
              node {
                value
                namespace
              }
            }
          }
          metafield(namespace: $namespace, key: $key) {
            value
          }
        }
      }
    }
  }
`;
